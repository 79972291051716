import React from 'react';

function ImagePlaceholder() {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='56' height='56' rx='8' fill='#D4FCF6' />
      <path
        d='M16.3333 26.5L19.6667 23.1667L28.8333 32.3333L34.6667 26.5L39.6667 31.5V16.3333H16.3333V26.5ZM14.6667 13H41.3333C41.7754 13 42.1993 13.1756 42.5118 13.4882C42.8244 13.8007 43 14.2246 43 14.6667V41.3333C43 41.7754 42.8244 42.1993 42.5118 42.5118C42.1993 42.8244 41.7754 43 41.3333 43H14.6667C14.2246 43 13.8007 42.8244 13.4882 42.5118C13.1756 42.1993 13 41.7754 13 41.3333V14.6667C13 14.2246 13.1756 13.8007 13.4882 13.4882C13.8007 13.1756 14.2246 13 14.6667 13ZM33.8333 24.6667C33.1703 24.6667 32.5344 24.4033 32.0656 23.9344C31.5967 23.4656 31.3333 22.8297 31.3333 22.1667C31.3333 21.5036 31.5967 20.8677 32.0656 20.3989C32.5344 19.9301 33.1703 19.6667 33.8333 19.6667C34.4964 19.6667 35.1323 19.9301 35.6011 20.3989C36.0699 20.8677 36.3333 21.5036 36.3333 22.1667C36.3333 22.8297 36.0699 23.4656 35.6011 23.9344C35.1323 24.4033 34.4964 24.6667 33.8333 24.6667Z'
        fill='#0B8372'
      />
    </svg>
  );
}

export default ImagePlaceholder;
