import { CategoryBar } from 'components/common';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CreationForm from './components/CreationForm';
import TagList from './components/List';
import styles from './styles.module.css';

function TagPage() {
  const [show, setShow] = useState(false);
  const [selectCategory, setSelectCategory] = useState(null);
  return (
    <div>
      <div className="d-flex justify-content-between">
        <CategoryBar setSelectedCategory={(value) => setSelectCategory(value)} />
        <Button className={styles.createButton} variant="success" onClick={() => setShow(true)}>
          Tạo thẻ
        </Button>
      </div>
      <TagList category={selectCategory} />
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CreationForm onHide={() => setShow(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TagPage;
