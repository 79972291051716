import React, { useEffect, useState } from 'react';
import SignUpForm from './components/Form';
import styled from 'styled-components';
import Logo from 'components/common/Logo';

const SignUpPage = ({ className }) => {
  return (
    <div className={className}>
      <Logo />
      <SignUpForm />
    </div>
  );
};

const StyledSignUpPage = styled(SignUpPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
`;

export default StyledSignUpPage;
