import React from 'react';

function Logo({ style }) {
  return (
    <img
      src='/logo.jpg'
      alt='logo'
      style={
        style || { width: '100%', maxWidth: '10rem', marginBottom: '2rem' }
      }
    />
  );
}

export default Logo;
