import React, { useEffect, useState } from 'react';
import RequestForm from './components/RequestForm';
import ResetForm from './components/ResetForm';
import styled from 'styled-components';
import Logo from 'components/common/Logo';
import { useSearchParams } from 'react-router-dom';

const SignUpPage = ({ className }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');
  const userId = searchParams.get('id');

  if (token && userId) {
    return (
      <div className={className}>
        <Logo />
        <ResetForm token={token} userId={userId} />
      </div>
    );
  } else {
    return (
      <div className={className}>
        <Logo />
        <RequestForm />
      </div>
    );
  }
};

const StyledSignUpPage = styled(SignUpPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  .button-container {
    width: 100%;
  }
`;

export default StyledSignUpPage;
