import orderApi from 'api/orderApi';
import { Loading } from 'components/common';
import { useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import Item from './Item';
const isEqual = require("react-fast-compare");

function OrderList({ filter, className }) {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const filterRef = useRef(filter);

  useEffect(() => {
    getOrdersData();
  }, [])

  useEffect(() => {
    if(isEqual(filter, filterRef.current)) return;
    setLoading(true);
    delete filter.state;
    getOrdersData();
    filterRef.current = filter;
  }, [filter]);

  const handlePageChange = (value) => {
    if (value < 0) return;
    setPage(value);

    orderApi
      .getOrderHistory(value, limit, filter)
      .then((res) => {
        setOrders(res.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const getOrdersData = () => {
    orderApi
      .getOrderHistory(0, limit, filter)
      .then((res) => {
        setOrders(res.data);
        setPage(0);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e.response.status);
        if(e?.response?.status === 401){
          localStorage.removeItem('phototd-token');
          localStorage.removeItem('phototd-rtoken');
          localStorage.removeItem('phototd-role');
          window.location.reload();
        }
      });
  }

  return (
    <div className={className}>
      {loading && <Loading />}
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Ngày tạo</th>
            <th>Mã đơn hàng</th>
            <th>Khách hàng</th>
            <th>Trạng thái</th>
            <th>Tình trạng in</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((child, idx) => {
            return <Item idx={idx} key={child._id} {...child} />;
          })}
        </tbody>
      </Table>
      
      {!loading && orders.length === 0 && <div>Không có dữ liệu</div>}
      
      <div className='d-flex justify-content-center'>
        <button className="btn btn-success" onClick={() => handlePageChange(page - 1)}>
          {'<'}
        </button>
        <span className="page-idx btn btn-success mx-2">Trang {page + 1}</span>
        <button className="btn btn-success" onClick={() => handlePageChange(page + 1)}>
          {'>'}
        </button>
      </div>
    </div>
  );
}

export default styled(OrderList)`
  position: relative;
`;
