import branchApi from 'api/branchApi';
import { CategoryBar } from 'components/common';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import HistoryList from '../components/HistoryList';

function HistoryPage({ className }) {
  const userRole = localStorage.getItem('phototd-role');
  const userMail = localStorage.getItem('phototd-email');
  const [state, setState] = useState(userRole === 'print' ? 3 : 'all');
  const [category, setCategory] = useState('all');
  const [isPrinted, setIsPrinted] = useState('all');
  const [branchs, setBranchs] = useState([]);
  const [branch, setBranch] = useState(userRole === 'print' ? '' : 'all');

  useEffect(() => {
    const BRANCH_PAGE = 0;
    const BRANCH_LIMIT = 10;
    branchApi
      .getBranchs(BRANCH_PAGE, BRANCH_LIMIT, userRole === 'print' ? {mail: userMail} : {})
      .then((res) => {
        setBranchs(res.data);
        setBranch(userRole === 'print' ? (res.data?.[0] || '') : 'all')
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  const filter = useMemo(() => {
    let retData = { category, state, isPrinted, branch };

    if (category === 'all') {
      delete retData.category;
    }

    if (state === 'all') {
      delete retData.state;
    } else {
      retData.state = Number(retData.state);
    }

    if (isPrinted === 'all') {
      delete retData.isPrinted;
    }

    if (branch === 'all' && userRole !== 'print') {
      delete retData.branch;
    }

    return retData;
  }, [state, category, isPrinted, branch]);

  const handlePrintedChange = (e) => {
    setIsPrinted(e.target.value);
  };

  const handleBranchChange = (e) => {
    setBranch(e.target.value);
  };

  return (
    <div className={className}>
      <div className="filter-bar d-flex">
        {userRole !== 'print' ? (
          <select className="filter-container me-3" onChange={(e) => setState(e.target.value)} value={state}>
            <option className="btn btn-outline-secondary me-3" value="all">
              Tất cả trạng thái
            </option>
            <option className="btn btn-outline-secondary me-3" value={0}>
              Đã tạo
            </option>
            <option className="btn btn-outline-secondary me-3" value={2}>
              Đã tiếp nhận và báo giá
            </option>
            <option className="btn btn-outline-secondary me-3" value={3}>
              Đã chuyển đến xưởng in
            </option>
            <option className="btn btn-outline-secondary me-3" value={4}>
              Đã giao hàng
            </option>
            <option className="btn btn-outline-secondary me-3" value={5}>
              Đã hoàn tất
            </option>
            <option className="btn btn-outline-secondary me-3" value={6}>
              Đã hủy
            </option>
          </select>
        ) : null}

        <CategoryBar setSelectedCategory={setCategory} />
        <select className="ms-3" defaultValue="all" onChange={handlePrintedChange}>
          <option value="all">Tất cả tình trạng in</option>
          <option value={true}>Đã in</option>
          <option value={false}>Chưa in</option>
        </select>
        <select className="ms-3" onChange={handleBranchChange}>
          {userRole !== 'print' && <option selected={true}>Tất cả chi nhánh</option>}
          {branchs.map((branch) => {
            return (
              <option key={branch._id} value={branch._id} >
                {branch.name}
              </option>
            );
          })}
        </select>
      </div>
      <HistoryList category={category} filter={filter} />
    </div>
  );
}

export default styled(HistoryPage)`
  margin-bottom: 5rem;

  .filter-bar {
    overflow-x: auto;
    /* position: sticky; */
    /* top: 0; */
    z-index: 1000;
    padding: 1rem 0;
    margin: 0;
  }
`;
