import { FaPrint, FaTag, FaHistory } from 'react-icons/fa';
import { BiCategory, BiBuildingHouse, BiStats } from 'react-icons/bi';

export const ADMIN_LAYOUT_MENU = [
  {
    label: 'Chờ xử lý',
    path: 'order',
    icon: <FaPrint />,
  },
  {
    label: 'Lịch sử in',
    path: 'history',
    icon: <FaHistory />,
  },
  {
    label: 'Thể loại',
    path: 'category',
    icon: <BiCategory />,
  },
  {
    label: 'Thẻ',
    path: 'tag',
    icon: <FaTag />,
  },
  {
    label: 'Chi nhánh',
    path: 'branch',
    icon: <BiBuildingHouse />,
  },
  {
    label: 'Thống kê',
    path: 'statistics',
    icon: <BiStats />,
  },
];
