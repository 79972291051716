import React, { useEffect, useState } from 'react';
import categoryApi from 'api/categoryApi';
import styled from 'styled-components';

export const CategoryBar = ({ setSelectedCategory }) => {
  const PAGE = 0;
  const LIMIT = 10;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    categoryApi
      .getCategories(PAGE, LIMIT)
      .then((res) => {
        res.data.sort((a, b) => a.priority - b.priority);
        setCategories(res.data);
        // if (res.data.length > 0) {
        //   setSelectedCategory(res.data[0]._id);
        // }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="spinner-border text-secondary" role="status"></div>;

  return (
    <select defaultValue={'all'} onChange={(e) => setSelectedCategory(e.target.value)}>
      <option value="all">Tất cả thể loại</option>
      {categories.map((child) => {
        return (
          <option key={child._id} value={child._id}>
            {child.name}
          </option>
        );
      })}
    </select>
  );
};
