import tagApi from 'api/tagApi';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ToastWrapper } from 'utils';
import TagItem from './Item';

function TagList({ category }) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if (category && category != 'all') {
      tagApi
        .getTags(0, limit, { category })
        .then((res) => {
          setPage(0);
          setTagList(res.data);
        })
        .catch((e) => {
          console.log(e);
          ToastWrapper(e.response.data.message, 'error');
        });
    } else {
      getTagsData(page, limit);
    }
  }, [category, limit]);

  const getTagsData = (page, limit) => {
    tagApi
        .getTags(page, limit)
        .then((res) => {
          setPage(0);
          setTagList(res.data);
        })
        .catch((e) => {
          console.log(e);
          ToastWrapper(e.response.data.message, 'error');
        });
  }

  const handlePageChange = (value) => {
    if (value < 0) return;
    
    if(category === 'all' || category === null) {
      tagApi
      .getTags(value, limit)
      .then((res) => {
        setPage(value);
        setTagList(res.data);
      })
      .catch((e) => {
        console.log(e);
        ToastWrapper(e.response.data.message, 'error');
      });
    } else {
      tagApi
      .getTags(value, limit, { category })
      .then((res) => {
        setPage(value);
        setTagList(res.data);
      })
      .catch((e) => {
        console.log(e);
        ToastWrapper(e.response.data.message, 'error');
      });
    }

    
  };

  return (
    <div>
      {tagList &&
        tagList.map((child) => {
          return <TagItem key={child._id} {...child} />;
        })}
      <div
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
        }}
      >
        <Button variant="success" onClick={() => handlePageChange(page - 1)}>
          Trang trước
        </Button>
        <span style={{ margin: '0 1rem' }}>Trang {page + 1}</span>
        <Button variant="success" onClick={() => handlePageChange(page + 1)}>
          Kế tiếp
        </Button>
      </div>
    </div>
  );
}

export default TagList;
