import categoryApi from 'api/categoryApi';
import tagApi from 'api/tagApi';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ToastWrapper } from 'utils';

function CategoryPage({ onHide }) {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const CATEGORY_MAX_PAGE = 0;
    const CATEGORY_MAX_LIMIT = 10;
    categoryApi
      .getCategories(CATEGORY_MAX_PAGE, CATEGORY_MAX_LIMIT)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById('formName').value;
    const description = document.getElementById('formDesc').value;
    const category = document.getElementById('formCategory').value;

    if (!name) {
      return ToastWrapper('Tên thẻ không được để trống', 'error');
    }

    if (!category) {
      return ToastWrapper('Vui lòng chọn thể loại cho thẻ', 'error');
    }

    const tag = {
      name,
      description,
      category,
    };

    tagApi
      .createTag(tag)
      .then((res) => {
        ToastWrapper('Thêm thẻ thành công', 'success');
        onHide();
      })
      .catch((e) => {
        console.log(e);
        ToastWrapper(e.response.data.message, 'error');
      });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label>Tên thẻ</Form.Label>
        <Form.Control type="text" placeholder="Nhập tên thẻ" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formDesc">
        <Form.Label>Mô tả thẻ</Form.Label>
        <Form.Control type="text" placeholder="Nhập mô tả thẻ" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formCategory">
        <Form.Label>Thể loại</Form.Label>
        <Form.Select>
          {categories &&
            categories.map((child) => {
              return (
                <option key={child._id} value={child._id}>
                  {child.name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>
      <Button variant="success" type="submit">
        Tạo thẻ
      </Button>
    </Form>
  );
}

export default CategoryPage;
