import React, { useEffect, useState } from 'react';
import userApi from 'api/userApi';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
import validator from 'validator';

function Form({ className }) {
  const [user, setUser] = useState();
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userApi
      .getUser()
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        ToastWrapper(e?.response?.data?.message);
        handleLogout();
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('phototd-token');
    localStorage.removeItem('phototd-rtoken');
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById('formName').value;
    const password = document.getElementById('formPassword').value;

    if (!name || name.trim() === '' || name.length === 0 || name.length > 30) {
      return setErrMsg('Tên có độ dài từ 1 đến 30 ký tự');
    }

    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
        returnScore: false,
      })
    ) {
      return setErrMsg('Mật khẩu không đúng');
    }

    setErrMsg('');

    userApi
      .updateUser(name, password)
      .then((res) => {
        return ToastWrapper('Cập nhật thông tin thành công', 'success');
      })
      .catch((e) => ToastWrapper(e?.response?.data?.message, 'error'));
  };

  return (
    <form onSubmit={handleSubmit} className={className}>
      <input
        className="form-control my-3"
        type="text"
        readOnly
        placeholder="Địa chỉ email"
        defaultValue={user?.email}
      />

      <input
        className="form-control my-3"
        type="text"
        placeholder="Tên của bạn"
        defaultValue={user?.name}
        id="formName"
      />

      <input
        className="form-control my-3"
        type="password"
        placeholder="Mật khẩu"
        id="formPassword"
      />

      <div className="error-msg mx-1 my-3">{errMsg}</div>

      <input
        type="submit"
        className="btn btn-primary my-3"
        value="Cập nhật thông tin"
      />
    </form>
  );
}

export default styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;

  .error-msg {
    color: red;
  }
`;
