import React from 'react';
import userApi from 'api/userApi';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
import validator from 'validator';

function Form({ className }) {
  const { register, handleSubmit } = useForm();
  const [errMsg, setErrMsg] = React.useState('');

  const onSubmit = ({ email, password, name }) => {
    if (!name || name.trim() === '' || name.length === 0 || name.length > 30) {
      return setErrMsg('Tên có độ dài từ 1 đến 30 ký tự');
    }

    if (!validator.isEmail(email))
      return setErrMsg('Vui lòng nhập đúng định dạng email');

    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
        returnScore: false,
      })
    )
      return setErrMsg(
        'Mật khẩu có độ dài tối thiểu 8 ký tự, bao gồm ít nhất 1 số, 1 chữ hoa và 1 chữ thường'
      );

    setErrMsg('');

    userApi
      .register(email, password, name)
      .then((res) => {
        localStorage.setItem('phototd-token', res.data.token);
        localStorage.setItem('phototd-rtoken', res.data.refreshToken);
        localStorage.setItem('phototd-role', res.data.role);

        ToastWrapper(res.message + '. Đang chuyển hướng...', 'success');
        setTimeout(() => {
          document.location.href = '/auth/profile';
        }, 2000);
      })
      .catch((err) => {
        return ToastWrapper(err?.response?.data?.message, 'error');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <input
        className="form-control mb-3"
        type="text"
        required
        placeholder="Tên của bạn"
        {...register('name')}
      />

      <input
        className="form-control mb-3"
        type="text"
        required
        placeholder="Địa chỉ email"
        {...register('email')}
      />

      <input
        className="form-control mb-3"
        type="password"
        required
        placeholder="Mật khẩu"
        {...register('password')}
      />

      <div className="err-msg">{errMsg}</div>

      <input
        type="submit"
        className="btn btn-outline-primary my-3"
        value="Đăng ký tài khoản"
      />
    </form>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 25rem;

  .err-msg {
    color: red;
  }

  .btn {
    min-width: 13rem;
  }
`;

export default StyledForm;
