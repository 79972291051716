import React from 'react';
import styled from 'styled-components';

function ActionButton({ active, children, onClick, loading, className }) {
  if (loading)
    return (
      <div className={className}>
        <div class="spinner-border text-success" role="status"></div>
      </div>
    );

  return (
    <div className={className}>
      <button
        type="button"
        className="action-button btn btn-outline-success my-2"
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

export default styled(ActionButton)`
  min-width: 10rem;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;

  .action-button {
    width: 100%;
    font-weight: bold;
    background-color: ${(props) =>
      props.active ? props.bgcolor || 'var(--bs-success)' : null};
    color: ${(props) => (props.active ? props.color || 'white' : null)};
    border-color: ${(props) => (props.active ? props.color || 'white' : null)};
  }
`;
