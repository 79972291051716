import userApi from 'api/userApi';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
import validator from 'validator';

function Form({ className }) {
  const { state } = useLocation();
  const { register, handleSubmit } = useForm();
  const [errMsg, setErrMsg] = React.useState('');

  const onSubmit = ({ email, password }) => {
    if (!validator.isEmail(email))
      return setErrMsg('Vui lòng nhập đúng định dạng email');

    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
        returnScore: false,
      })
    )
      return setErrMsg(
        'Mật khẩu có độ dài tối thiểu 8 ký tự, bao gồm ít nhất 1 số, 1 chữ hoa và 1 chữ thường'
      );

    setErrMsg('');

    userApi
      .login(email, password)
      .then((res) => {
        console.log(res)
        localStorage.setItem('phototd-token', res.data.token);
        localStorage.setItem('phototd-rtoken', res.data.refreshToken);
        localStorage.setItem('phototd-role', res.data.role);
        localStorage.setItem('phototd-email', email);
        ToastWrapper(res.message + '. Đang chuyển hướng...', 'success');

        setTimeout(() => {
          if (state) {
            document.location.href = state.from.pathname;
          } else {
            document.location.href = '/auth/profile';
          }
        }, 2000);
      })
      .catch((e) => {
        return ToastWrapper(e?.response?.data?.message, 'error');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <input
        className="form-control mb-3"
        type="text"
        required
        placeholder="Địa chỉ email"
        {...register('email')}
      />

      <input
        className="form-control mb-3"
        type="password"
        required
        placeholder="Mật khẩu"
        {...register('password')}
      />

      <div className="err-msg">{errMsg}</div>

      <input
        type="submit"
        className="btn btn-primary my-3"
        value="Đăng nhập ngay"
      />
      <a className="btn btn-outline-primary" href="/auth/signup">
        Đăng ký tài khoản
      </a>

      <p className="my-3 text-center">
        Bạn quên mật khẩu?{' '}
        <a
          className=""
          href="/auth/password-reset"
          target={'_blank'}
          rel="noopener noreferer"
        >
          Khôi phục
        </a>
      </p>
    </form>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 25rem;

  .err-msg {
    color: red;
  }

  .btn {
    min-width: 13rem;
  }
`;

export default StyledForm;
