import React from 'react';
import styled from 'styled-components';

function HomeLayout({ children }) {
  return <Styles>{children}</Styles>;
}

export default HomeLayout;

const Styles = styled.div`
  @media only screen and (min-width: 768px) {
      width: 75%;
      margin: 0 auto;
  }
`
