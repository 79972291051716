import React, { useEffect, useState } from 'react';
import UpdateForm from './components/UpdateForm';
import styled from 'styled-components';

const Profile = ({ className }) => {
  const handleLogout = () => {
    localStorage.removeItem('phototd-token');
    localStorage.removeItem('phototd-rtoken');
    window.location.reload();
  };

  return (
    <div className={className}>
      <h1 className='my-5'>Thông tin tài khoản</h1>
      <UpdateForm />
      <button className='btn btn-outline-danger w-100' onClick={handleLogout}>
        Đăng xuất
      </button>
    </div>
  );
};

export default styled(Profile)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  padding: 2rem;

  .btn {
    max-width: 30rem;
  }
`;
