import categoryApi from 'api/categoryApi';
import { useEffect, useState } from 'react';
import { ToastWrapper } from 'utils';
import CategoryItem from './Item';

function List() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    categoryApi
      .getCategories()
      .then((res) => {
        setCategoryList(res.data);
        res.data.sort((a, b) => a.priority - b.priority);
      })
      .catch((err) => {
        console.log(err);
        ToastWrapper(err.response.data.message, 'error');
      });
  }, []);

  return (
    <div>
      {categoryList &&
        categoryList.map((child) => {
          return <CategoryItem {...child} />;
        })}
    </div>
  );
}

export default List;
