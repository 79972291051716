import { NotFound } from 'components/common';
import AdminLayoutV2 from 'components/layout/AdminLayoutV2';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BranchPage,
  CategoryPage,
  CreationPage,
  HomePage,
  LoginPage,
  OrderPage,
  HistoryPage,
  PasswordRecoveryPage,
  PricePage,
  ProfilePage,
  SignUpPage,
  TagPage,
  StatisticPage
} from './pages';
import { store } from './redux/index';
import { ADMIN_LAYOUT_MENU } from 'constants/menu';
import { ModalProvider } from 'react-modal-hook'
import HomeLayout from 'shared/layouts/HomeLayout';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={
            <HomeLayout>
              <HomePage />
            </HomeLayout>
          } />
          <Route path="/order" element={<PricePage />} />
          <Route
            path="admin"
            element={
              <RequiredAuth>
                <AdminLayoutV2 title='In ấn Thời Đại' menu={ADMIN_LAYOUT_MENU}/>
              </RequiredAuth>
            }
          >
            <Route index element={<OrderPage />} />
            <Route path="order" element={<OrderPage />} />
            <Route path="history" element={<HistoryPage />} />
            <Route path="statistics" element={<StatisticPage />} />
            <Route path="category" element={<CategoryPage />} />
            <Route path="branch" element={<BranchPage />} />
            <Route path="creation" element={<CreationPage />} />
            <Route path="tag" element={<TagPage />} />
          </Route>
          <Route path="auth">
            <Route index element={<LoginPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route path="password-reset" element={<PasswordRecoveryPage />} />
            <Route
              path="profile"
              element={
                <RequiredAuth>
                  <ProfilePage />
                </RequiredAuth>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </Provider>
  );
}

export default App;

const RequiredAuth = ({ children }) => {
  const location = useLocation();

  const token = localStorage.getItem('phototd-token');
  if (!token) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  return children;
};
