import { useState } from 'react';
import userApi from 'api/userApi';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
const validator = require('validator');

function Form({ className, userId, token }) {
  const { register, handleSubmit } = useForm();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ password, passwordRepeat }) => {
    if (
      !validator.isStrongPassword(password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
        returnScore: false,
      })
    )
      return setErrMsg(
        'Mật khẩu có độ dài tối thiểu 8 ký tự, bao gồm ít nhất 1 số, 1 chữ hoa và 1 chữ thường'
      );

    if (password !== passwordRepeat) {
      return setErrMsg('Mật khẩu không trùng khớp');
    }

    setErrMsg('');
    setLoading(true);

    userApi
      .resetPassword(userId, token, password)
      .then((res) => {
        setLoading(false);
        ToastWrapper(res.message + '. Đang chuyển hướng...', 'success');

        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        return ToastWrapper(err?.response?.data?.message, 'error');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <input
        className='form-control mb-3'
        type='password'
        required
        placeholder='Mật khẩu mới'
        {...register('password')}
      />
      <input
        className='form-control'
        type='password'
        required
        placeholder='Nhập lại mật khẩu mới'
        {...register('passwordRepeat')}
      />
      <div className='error-msg mx-1 my-3'>{errMsg}</div>

      {loading ? (
        <div className='btn btn-outline-success my-3'>Đang gửi...</div>
      ) : (
        <input
          type='submit'
          className='btn btn-outline-success my-3'
          value='Đổi mật khẩu'
        />
      )}
    </form>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
  .error-msg {
    color: red;
  }
`;

export default StyledForm;
