const generateCreatedMessage = ({ name, orderCode }) => {
    let quoteTime = new Date();
    quoteTime.setMinutes(quoteTime.getMinutes() + 30);
    return `Chào bạn ${name}, đơn hàng in ấn ${orderCode} của bạn đang được xử lý và sẽ được báo giá vào lúc ${quoteTime.toLocaleTimeString()}, ngày ${quoteTime.toLocaleDateString('en-GB')}. Bạn vui lòng giữ liên lạc với chúng mình vào thời gian này để quá trình in được diễn ra nhanh chóng. Xin cảm ơn và chúc bạn một ngày tốt lành!`
}

const generateProcessedMessage = ({name, orderCode}, quotationLink) => {
    return `Chào bạn ${name}, đơn hàng ${orderCode} của bạn đã được tiếp nhận và đang chờ xác nhận in từ bạn. Bạn vui lòng xem báo giá và thanh toán theo hướng dẫn tại: ${quotationLink}. Thanh toán sẽ được xác nhận TỰ ĐỘNG và chuyển đơn hàng đến xưởng in. Bạn sẽ nhận được thông báo khi quá trình in hoàn tất.`
}

const generatePrintingMessage = () => {
    return `Thông báo: tài liệu của bạn đã được chuyển đến xưởng in. Sau khi quá trình in được hoàn tất, bạn sẽ nhận được thông báo về thời gian giao hàng.`
}

const generatePrintedMessage = ({name, orderCode, isDelivered, deliveryTime, office, address}) => {
    if(isDelivered) {
        deliveryTime = new Date(deliveryTime);
        return `Thông báo: Đơn hàng ${orderCode} của bạn đã hoàn tất in và sẽ được giao đến bạn vào lúc ${deliveryTime.toLocaleTimeString()}, ngày ${deliveryTime.toLocaleDateString('en-GB')} tại ${address}. Bạn vui lòng giữ liên lạc trong thời gian này để quá trình giao hàng được diễn ra nhanh chóng. Cảm ơn bạn ${name} đã sử dụng dịch vụ của chúng mình. Phản ánh về chất lượng dịch vụ tại hotline: 0877876877.`
    } else {
        return `Thông báo: Đơn hàng ${orderCode} của bạn đã hoàn tất in. Bạn có thể đến nhận hàng tại ${office?.name}. Cảm ơn bạn ${name} đã sử dụng dịch vụ của chúng mình. Phản ánh về chất lượng dịch vụ tại hotline: 0876877789.`
    }
}

export {
    generateCreatedMessage,
    generateProcessedMessage,
    generatePrintingMessage,
    generatePrintedMessage
}
