import orderApi from 'api/orderApi';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { MdDelete } from 'react-icons/md';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';

function PriceTable({ id, show, setShow, data, setData, cost, setCost, className }) {
  const [saving, setSaving] = useState(false);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [currentQuantity, setCurrentQuantity] = useState(null);
  const [currentDesc, setCurrentDesc] = useState('');
  useEffect(() => {
    let tempCost = 0;
    data.forEach((child) => (tempCost += child.costPrice));
    setCost(tempCost);
  }, [data]);

  const handleSubmit = () => {
    const costData = {
      costDesc: currentDesc,
      costUnit: Number(currentUnit),
      costQuantity: Number(currentQuantity),
    };

    if (currentDesc && currentUnit && currentQuantity) {
      costData.costPrice = costData.costUnit * costData.costQuantity;
      setData((prev) => [...prev, costData]);
    } else {
      ToastWrapper('Thông tin không hợp lệ', 'error');
    }
  };

  const handleRemove = (idx) => {
    const newData = [...data.slice(0, idx), ...data.slice(idx + 1)];
    setData(newData);
  };

  const handleSave = () => {
    setSaving(true);
    orderApi
      .updateOrder(id, { costData: data, cost })
      .then((res) => {
        ToastWrapper('Lưu thành công', 'success');
        setSaving(false);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        ToastWrapper(err.response.data.message, 'error');
        setSaving(false);
        setShow(false);
      });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      className={className}
      fullscreen={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Tổng cộng: <CurrencyInput name="cost" value={cost} decimalsLimit={0} readOnly />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="cost-table-footer" onSubmit={handleSubmit}>
          <table className="cost-table">
            <tr className="cost-table-header">
              <th className="cost-table-header-item">Mô tả</th>
              <th className="cost-table-header-item">Đơn giá</th>
              <th className="cost-table-header-item">Số lượng</th>
              <th className="cost-table-header-item">Thành tiền</th>
              <th className="cost-table-header-item">Hành động</th>
            </tr>
            {data.map((child, idx) => (
              <tr key={idx} className="cost-table-body">
                <td className="cost-table-body-item">{child.costDesc}</td>
                <td className="cost-table-body-item">
                  <CurrencyInput
                    className="form-control "
                    name="costUnit"
                    type="text"
                    defaultValue={child.costUnit}
                    decimalsLimit={0}
                    readOnly
                  />
                </td>
                <td className="cost-table-body-item">
                  <CurrencyInput
                    className="form-control"
                    name="costQuantity"
                    type="text"
                    defaultValue={child.costQuantity}
                    decimalsLimit={0}
                    readOnly
                  />
                </td>
                <td className="cost-table-body-item">
                  <CurrencyInput
                    className="form-control"
                    name="costPrice"
                    type="text"
                    defaultValue={child.costPrice}
                    decimalsLimit={0}
                    readOnly
                  />
                </td>

                <td className="cost-table-body-item">
                  <button className="btn btn-danger my-2" onClick={() => handleRemove(idx)} type="button">
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td className="cost-table-footer-item">
                <input
                  className="form-control"
                  rows={3}
                  name="costDesc"
                  type="text"
                  defaultValue={currentDesc}
                  onChange={(e) => setCurrentDesc(e.target.value)}
                />
              </td>
              <td className="cost-table-footer-item">
                <CurrencyInput
                  className="form-control"
                  name="costUnit"
                  type="text"
                  defaultValue={currentUnit}
                  decimalsLimit={0}
                  onValueChange={(value) => setCurrentUnit(value)}
                />
              </td>
              <td className="cost-table-footer-item">
                <CurrencyInput
                  className="form-control"
                  name="costQuantity"
                  type="text"
                  defaultValue={currentQuantity}
                  decimalsLimit={0}
                  onValueChange={(value) => setCurrentQuantity(value)}
                />
              </td>
              <td className="cost-table-footer-item">
                <CurrencyInput
                  className="form-control"
                  name="costPrice"
                  type="text"
                  value={currentQuantity * currentUnit}
                  decimalsLimit={0}
                  readOnly
                />
              </td>

              <td className="cost-table-footer-item">
                <input className="btn btn-primary my-3" type="button" onClick={handleSubmit} value="Thêm" />
              </td>
            </tr>
          </table>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          Đóng
        </Button> */}
        {saving ? (
          <Button variant="primary">Đang lưu...</Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              handleSave();
            }}
          >
            Đóng và lưu lại
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default styled(PriceTable)`
  .cost-table {
    width: 100%;
  }

  .cost-table-footer {
    width: 100%;
  }

  .cost-table-header-item {
    width: 20%;
    text-align: center;
  }

  .cost-table-body-item {
    width: 20%;
    text-align: center;
  }

  .cost-table-footer-item {
    width: 20%;
    text-align: center;
  }
`;
