import { useState } from 'react';
import userApi from 'api/userApi';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
const validator = require('validator');

function Form({ className }) {
  const { register, handleSubmit } = useForm();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ email }) => {
    if (!email || !validator.isEmail(email)) {
      return setErrMsg('Vui lòng nhập địa chỉ email hợp lệ');
    }

    setLoading(true);

    userApi
      .requestPasswordReset(email)
      .then((res) => {
        setLoading(false);
        return ToastWrapper(res.message, 'success');
      })
      .catch((err) => {
        setLoading(false);
        return ToastWrapper(err?.response?.data?.message, 'error');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <input
        className='form-control'
        type='text'
        required
        placeholder='Địa chỉ email của bạn'
        {...register('email')}
      />
      <div className='error-msg mx-1 my-3'>{errMsg}</div>

      {loading ? (
        <div className='btn btn-outline-success my-3'>Đang gửi...</div>
      ) : (
        <input
          type='submit'
          className='btn btn-outline-success my-3'
          value='Khôi phục tài khoản'
        />
      )}
    </form>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
  .error-msg {
    color: red;
  }
`;

export default StyledForm;
