import categoryApi from 'api/categoryApi';
import tagApi from 'api/tagApi';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ToastWrapper } from 'utils';

function TagItem(props) {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(props.category);

  useEffect(() => {
    const CATEGORY_MAX_PAGE = 0;
    const CATEGORY_MAX_LIMIT = 10;
    categoryApi
      .getCategories(CATEGORY_MAX_PAGE, CATEGORY_MAX_LIMIT)
      .then((res) => {
        res.data.sort((a, b) => a.priority - b.priority);
        setCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = document.getElementById('formName' + props._id).value;
    const description = document.getElementById('formDesc' + props._id).value;
    const category = document.getElementById('formCategory' + props._id).value;

    if (!name) {
      return ToastWrapper('Tên thể loại không được để trống', 'error');
    }

    if (!category) {
      return ToastWrapper('Vui lòng chọn thể loại cho thẻ', 'error');
    }

    const tag = {
      name,
      description,
      category,
    };

    tagApi
      .updateTag(props._id, tag)
      .then((res) => {
        ToastWrapper('Cập nhật thể loại thành công', 'success');
      })
      .catch((e) => {
        console.log(e);
        ToastWrapper(e.response.data.message, 'error');
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId={'formName' + props._id}>
        <Form.Label>Tên thẻ</Form.Label>
        <Form.Control type="text" placeholder="Nhập tên thẻ" defaultValue={props?.name} />
      </Form.Group>
      <Form.Group className="mb-3" controlId={'formDesc' + props._id}>
        <Form.Label>Mô tả</Form.Label>
        <Form.Control type="text" placeholder="Nhập mô tả thẻ" defaultValue={props?.description} />
      </Form.Group>
      <Form.Group className="mb-3" controlId={'formCategory' + props._id}>
        <Form.Label>Thể loại</Form.Label>
        <Form.Select value={category} onChange={(e) => setCategory(e.target.value)}>
          {categories &&
            categories.map((child) => {
              return (
                <option key={child._id} value={child._id}>
                  {child.name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>
      <Button className="mt-1 mb-5" variant="success" type="submit">
        Cập nhật thẻ
      </Button>
    </Form>
  );
}

export default TagItem;
