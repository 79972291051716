import React, { useEffect, useState } from 'react';
import LoginForm from './components/Form';
import styled from 'styled-components';
import Logo from 'components/common/Logo';

const SignUpPage = ({ className }) => {
  return (
    <div className={className}>
      <Logo />
      <LoginForm />
    </div>
  );
};

const StyledSignUpPage = styled(SignUpPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  .button-container {
    width: 100%;
  }
`;

export default StyledSignUpPage;
