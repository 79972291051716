import React, { useEffect } from 'react';
import CreationForm from './components/CreationForm';
import styles from './styles.module.css';

function OrderCreation(props) {
  useEffect(() => {});
  return (
    <div>
      <CreationForm />
    </div>
  );
}

export default OrderCreation;
