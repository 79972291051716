import orderApi from 'api/orderApi';
import Asterisk from 'assets/images/Asterisk';
import AccountInfo from 'pages/home/components/AccountInfo';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { useTable } from 'react-table';
import ZaloLink from 'shared/components/link/ZaloLink';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
import { formatCurrency } from 'utils/commonUtils';

function PricePage(props) {
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const token = searchParams.get('token');
  const [order, setOrder] = useState(null);

  const data = React.useMemo(() => {
    if (!order || !order?.costData) return [];

    return order.costData;
  }, [order]);

  const totalPayment = useMemo(() => {
    const total = (order?.cost || 0) - (order?.discount || 0);
    return total > 0 ? total : 0;
  }, [order]);

  const remainPayment = useMemo(() => {
    const remain = totalPayment - order?.cash;
    return remain > 0 ? remain : 0;
  }, [order, totalPayment]);

  const returnPayment = useMemo(() => {
    const cashback = order?.cash - totalPayment;
    return cashback > 0 ? cashback : 0;
  }, [order, totalPayment]);



  const columns = React.useMemo(
    () => [
      {
        Header: 'Nội dung',
        accessor: 'costDesc',
      },
      {
        Header: 'Đơn giá (VNĐ)',
        accessor: 'costUnit',
        Cell: ({ row }) => {
          return <span>{formatCurrency(row?.values?.costUnit)}</span>;
        },
      },
      {
        Header: 'Số lượng',
        accessor: 'costQuantity',
        Cell: ({ row }) => {
          return <span>{formatCurrency(row?.values?.costQuantity)}</span>;
        },
      },
      {
        Header: 'Thành tiền (VNĐ)',
        accessor: 'costPrice',
        Cell: ({ row }) => {
          return formatCurrency(row?.values?.costPrice);
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  useEffect(() => {
    if (id && token) {
      orderApi
        .getQuotationById(id, token)
        .then((res) => {
          setOrder(res.data);
        })
        .catch((err) => ToastWrapper(err?.response?.data?.message));
    }
  }, [id, token]);

  if (!token) {
    return <p>Không tìm thấy báo giá hoặc liên kết đã hết hạn</p>;
  }

  return (
    <Styles>
      <Container>
        <Row>
          <h3>Trung tâm in ấn online iSinhvien</h3>
        </Row>
        <Row>
          <h3>Báo giá đến quý khách hàng</h3>
        </Row>
        <Row>
          <Col>
            <Row>
              <div className='label'>Khách hàng</div>
              <div>{order?.name || 'Chưa nhập'}</div>
            </Row>
            <Row>
              <div className='label'>Mã đơn hàng</div>
              <div>#{order?.orderCode}</div>
            </Row>
            <Row>
              <div className='label'>Số điện thoại</div>
              <div>{order?.tel}</div>
            </Row>
            <Row>
              <div className='label'>Nhận tại</div>
              <div>{order?.address || order?.office?.name || 'Không có'}</div>
            </Row>
          </Col>
          <Col>
            <Row>
              <div className='label'>Thể loại in</div>
              <div>{order?.category?.name}</div>
            </Row>
            <Row>
              <div className='label'>Đơn vị in</div>
              <div>{order?.branch?.name || 'Không có'}</div>
            </Row>
            <Row>
              <div className='label'>Nội dung chuyển khoản</div>
              <div>IN {order?.orderCode}</div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Button variant='success' className='my-2 py-2' onClick={() => setShow(true)}>
            <b>THANH TOÁN ĐƠN HÀNG TẠI ĐÂY</b>
          </Button>
        </Row>
        <Row>
          <Table {...getTableProps()} striped bordered hover>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th>#</th>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <td>{idx + 1}</td>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} style={{ fontWeight: 'bold' }}>
                  Tổng cộng
                </td>
                <td>
                  <span>{formatCurrency(order?.cost)}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ fontWeight: 'bold' }}>
                  Được giảm giá
                </td>
                <td>
                  <span>{formatCurrency(order?.discount || 0)}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ fontWeight: 'bold' }}>
                  Đã thanh toán
                </td>
                <td>
                  <span>{formatCurrency(order?.cash || 0)}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ fontWeight: 'bold' }}>
                  Còn nợ
                </td>
                <td>
                  <span>{formatCurrency(remainPayment)}</span>
                </td>
              </tr>
              <tr>
                <td colSpan={4} style={{ fontWeight: 'bold' }}>
                  Trả lại
                </td>
                <td>
                  <span>{formatCurrency(returnPayment)}</span>
                </td>
              </tr>
            </tfoot>
          </Table>
          <Row>
            <div>
              (<Asterisk color='red' />) Thanh toán được duyệt tự động và được
              cập nhật tại mục{' '}
              <b className='text-uppercase text-danger'>Đã thanh toán</b>, quý
              khách hàng vui lòng nhập{' '}
              <b className='text-uppercase text-danger'>Chính xác</b> nội dung
              chuyển khoản
            </div>
            <div>
              (<Asterisk color='red' />) Trong trường hợp ngân hàng gửi hoặc
              nhận đang bảo trì, giá trị trên sẽ không được cập nhật ngay tại
              thời điểm thanh toán. Quý khách vui lòng gửi yêu cầu duyệt thủ
              công tại Zalo OA:{' '}
              <ZaloLink tel='4013961016678131109'>
                Trung tâm dịch vụ sinh viên iStudent
              </ZaloLink>{' '}
              để được hỗ trợ.
            </div>
          </Row>
          <AccountInfo
            show={show}
            setShow={setShow}
            code={order?.orderCode}
            amount={remainPayment}
          />
        </Row>
      </Container>
    </Styles>
  );
}

export default React.memo(PricePage);

const Styles = styled.div`
  margin: 1rem 0 5rem;

  h3 {
    color: #00bcd4;
  }

  table {
    margin-top: 1rem;
  }

  .label {
    font-weight: bold;
  }
`;
