import branchApi from 'api/branchApi';
import categoryApi from 'api/categoryApi';
import orderApi from 'api/orderApi';
import tagApi from 'api/tagApi';
import DriveIcon from 'assets/icons/drive-icon.svg';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { AiOutlineMinus } from 'react-icons/ai';
import styled from 'styled-components';
import { ToastWrapper } from 'utils';
import ActionButton from './ActionButton';
import PriceTable from './PriceTable';
import ReactDatePicker from 'react-datepicker';
import Timeline from './Timeline';
import { generateCreatedMessage, generatePrintedMessage, generatePrintingMessage, generateProcessedMessage } from 'utils/helpers/generateOrderMessage';
import fileApi from 'api/fileApi';

function OrderItem({ className, idx, ...order }) {
  const [key, setKey] = useState(order?._id);
  const [showOrder, setShowOrder] = useState(false);
  const [showDocumentList, setShowDocumentList] = useState(false);
  const [data, setData] = useState(order);
  const [cash, setCash] = useState(order.cash);
  const [stateLoading, setStateLoading] = useState(false);
  const [branchs, setBranchs] = useState([]);
  const [costData, setCostData] = useState(order.costData || []);
  const [showTable, setShowTable] = useState(false);
  const [tags, setTags] = useState(order.tags || []);
  const [tagList, setTagList] = useState([]);
  const [tagShow, setTagShow] = useState(false);
  const [userRole, setUserRole] = useState(localStorage.getItem('phototd-role'));
  const [categories, setCategories] = useState([]);
  const [quotationToken, setQuotationToken] = useState(order?.token || '');
  const ORIGINAL_URL = window.location.origin;
  const quotationLink = `${ORIGINAL_URL}/order?id=${data._id}&token=${quotationToken}`;
  const [quotationCopied, setQuotationCopied] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState(order?.deliveryTime ? new Date(order?.deliveryTime) : null);
  const [message, setMessage] = useState('');
  const [messageCopied, setMessageCopied] = useState(false);

  useEffect(() => {
    const MAX_BRANCH_PAGE = 0;
    const MAX_BRANCH_LIMIT = 10;
    branchApi
      .getBranchs(MAX_BRANCH_PAGE, MAX_BRANCH_LIMIT)
      .then((res) => {
        setBranchs(res?.data);
      })
      .catch((err) => ToastWrapper(err?.response?.data?.message));
  }, []);

  useEffect(() => {
    const MAX_CATEGORY_PAGE = 0;
    const MAX_CATEGORY_LIMIT = 10;
    categoryApi
      .getCategories(MAX_CATEGORY_PAGE, MAX_CATEGORY_LIMIT)
      .then((res) => {
        setCategories(res?.data);
      })
      .catch((err) => ToastWrapper(err?.response?.data?.message));
  }, []);

  useEffect(() => {
    const MAX_TAG_PAGE = 0;
    const MAX_TAG_LIMIT = 100;
    tagApi
      .getTags(MAX_TAG_PAGE, MAX_TAG_LIMIT, { category: data?.category })
      .then((res) => {
        setTagList(res?.data);
      })
      .catch((e) => console.log(e));
  }, [data?.category]);

  useEffect(()=> {
    let msg = '';

    if(data?.state === 0) {
      msg = generateCreatedMessage(data);
    } else if(data?.state === 2) {
      msg = generateProcessedMessage(data, quotationLink);
    } else if(data?.state === 3) {
      msg = generatePrintingMessage();
    }

    if(data?.isPrinted) {
      msg = generatePrintedMessage(data);
    }

    setMessage(msg);
  }, [data, quotationLink]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, tel, zalo, address, instruction, note, coupon } = e.target;
    const order = {
      name: name?.value,
      tel: tel?.value,
      zalo: zalo?.value,
      address: address?.value,
      instruction: instruction?.value,
      note: note?.value,
      cash: Number(cash),
      tags: [],
      coupon: coupon?.value,
    };

    for (let t of tags) {
      order.tags.push(t._id);
    }

    if(deliveryTime) {
      order.deliveryTime = deliveryTime.getTime();
    }

    orderApi
      .updateOrder(data?._id, order)
      .then((res) => {
        setData(res?.data);
        ToastWrapper('Cập nhật thông tin đơn hàng thành công', 'success');
      })
      .catch((e) => {
        console.log(e);
        ToastWrapper(e?.response?.data?.message, 'error');
      });
  };

  const reloadOrder = () => {
    orderApi.getOrder(data?._id).then(res=> {
      if(res?.data) {
        setData(res.data);
        setKey(Math.random().toString())
      }
    }).catch(e=>console.log(e))
  }

  const updateState = (value) => {
    setStateLoading(true);
    orderApi
      .updateState(data?._id, value, order?.timeline)
      .then((res) => {
        setData(res?.data);
        setStateLoading(false);
      })
      .catch((e) => {
        setStateLoading(false);
        console.log(e)
      });
  };

  const updateBranch = (e) => {
    if (e.target.value) {
      orderApi
        .updateBranch(data?._id, e.target.value)
        .then((res) => {
          setData(res?.data);
          ToastWrapper('Đã thay đổi đơn vị in thành công', 'success');
        })
        .catch((e) => {
          ToastWrapper(e?.response?.data?.message, 'error');
        });
    }
  };

  const updateCategory = (e) => {
    if (e.target.value) {
      orderApi
        .updateOrder(data?._id, { category: e.target.value })
        .then((res) => {
          setData(res?.data);
          ToastWrapper('Đã thay đổi thể loại thành công', 'success');
        })
        .catch((e) => {
          ToastWrapper(e?.response?.data?.message, 'error');
        });
    }
  };

  const handlePrinted = () => {
    orderApi
      .updateOrder(data?._id, { isPrinted: !data?.isPrinted })
      .then((res) => {
        setData(res?.data);
        ToastWrapper('Đã cập nhật trạng thái thành công', 'success');
      })
      .catch((e) => {
        ToastWrapper(e?.response?.data?.message, 'error');
      });
  };

  const handleAddTag = (tag) => {
    for (let child of tags) {
      if (child._id === tag._id) {
        return;
      }
    }

    setTags([...tags, tag]);
  };

  const handleRemoveTag = (tag) => {
    const newTags = tags.filter((t) => t._id !== tag._id);
    setTags(newTags);
  };

  const createQuotationLink = () => {
    orderApi
      .createQuotationToken(data?._id)
      .then((res) => {
        setQuotationToken(res?.data?.token);
      })
      .catch((e) => console.log(e?.response?.data));
  };

  const copyQuotationLink = () => {
    navigator.clipboard
      .writeText(quotationLink)
      .then((_) => setQuotationCopied(true))
      .catch((err) => console.log(err));
  };

  const copyMessage = () => {
    navigator.clipboard
      .writeText(message)
      .then((_) => setMessageCopied(true))
      .catch((err) => console.log(err));
  }

  const handleDocumentButton = () => setShowDocumentList(true);
  const getSignedUrl = (fileId) => {
    fileApi
      .getSignedUrl(fileId)
      .then((res) => {
        console.log(res)
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', res?.data?.url)
        downloadLink.setAttribute('rel', 'noopener noreferrer')
        downloadLink.setAttribute('target', '_blank')
        downloadLink.click();
      })
      .catch((e) => console.log(e));
  };

  if (data) {
    const createdDate = new Date(data?.createdAt);

    return (
      <>
        <tr onClick={() => setShowOrder(true)}>
          <td>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                defaultValue={createdDate.toLocaleDateString('en-GB')}
                readOnly
              />
            </div>
          </td>
          <td>
            <div className='d-flex'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  defaultValue={`#${data?.orderCode?.substring(
                    0,
                    6
                  )}-${data?.orderCode?.substring(6)}`}
                  readOnly
                />
              </div>
            </div>
          </td>
          <td>
            <input
              type='text'
              className='form-control'
              value={data?.name}
              readOnly
            />
          </td>
          <td>
            <input
              type='text'
              className='form-control'
              value={data?.tel}
              readOnly
            />
          </td>
          <td>
            {data?.deliveryType === 'home' ? (
              <input
                type='text'
                className='form-control'
                defaultValue={data?.address}
                name='address'
                disabled
              />
            ) : (
              <input
                type='text'
                className='form-control'
                disabled
                value={data?.office?.name}
              />
            )}
          </td>
          <td>
            {data?.state === 0 && (
              <span className='text-secondary fw-bold'>Vừa tạo</span>
            )}{' '}
            {data?.state === 2 && (
              <span className='text-warning fw-bold'>
                Đã tiếp nhận và báo giá{' '}
              </span>
            )}
            {data?.state === 3 && (
              <span className='text-primary fw-bold'>
                Đã chuyển đến <strong>{data?.branch?.name}</strong>
              </span>
            )}
            {data?.state === 4 && (
              <span className='text-info fw-bold'>Đã giao hàng</span>
            )}
            {data?.state === 5 && (
              <span className='text-success fw-bold'>Đã hoàn tất</span>
            )}
            {data?.state === 6 && (
              <span className='text-danger fw-bold'>Đã huỷ</span>
            )}
          </td>
          <td>
            {data?.isPrinted ? (
              <span className='text-success fw-bold'>Đã in</span>
            ) : (
              <span className='text-danger fw-bold'>Chưa in</span>
            )}
          </td>
        </tr>

        <Modal show={showOrder} onHide={() => setShowOrder(false)} fullscreen>
          <Modal.Header closeButton>
            <Modal.Title>
              {`Đơn hàng #${data?.orderCode?.substring(
                0,
                6
              )}-${data?.orderCode?.substring(6)} `}
              <button onClick={reloadOrder} className='btn btn-primary'>
                Tải lại
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body key={key}>
            <Timeline data={data?.timeline} current={data?.state} />
            <form className={className} onSubmit={handleSubmit}>
              <div className='mx-2 order-container d-flex flex-wrap flex-grow-5 justify-content-between'>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-date'>
                    Ngày tạo
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    defaultValue={createdDate.toLocaleDateString('en-GB')}
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-date'>
                    Giờ tạo
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    defaultValue={createdDate.toLocaleTimeString('en-GB')}
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-name'>
                    Khách hàng
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    defaultValue={data?.name}
                    name='name'
                    readOnly={
                      userRole !== 'admin' &&
                      userRole !== 'mod' &&
                      userRole !== 'manager'
                    }
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-tel'>
                    Số điện thoại
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    defaultValue={data?.tel}
                    name='tel'
                    readOnly={
                      userRole !== 'admin' &&
                      userRole !== 'mod' &&
                      userRole !== 'manager'
                    }
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-zalo'>
                    Zalo
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    defaultValue={data?.zalo}
                    name='zalo'
                    readOnly={
                      userRole !== 'admin' &&
                      userRole !== 'mod' &&
                      userRole !== 'manager'
                    }
                  />
                  <a
                    className='btn btn-outline-primary mt-2'
                    href={`https://zalo.me/${data?.zalo}`}
                    rel='noopenner noreferrer'
                    target='_blank'
                  >
                    Mở zalo
                  </a>
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-address'>
                    {data?.deliveryType === 'home'
                      ? 'Giao tận nơi'
                      : 'Nhận tại cửa hàng'}
                  </label>
                  {data?.deliveryType === 'home' ? (
                    <input
                      type='text'
                      className='form-control'
                      defaultValue={data?.address}
                      name='address'
                      readOnly={
                        userRole !== 'admin' &&
                        userRole !== 'mod' &&
                        userRole !== 'manager'
                      }
                    />
                  ) : (
                    <input
                      type='text'
                      className='form-control'
                      disabled
                      value={data?.office?.name}
                    />
                  )}
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-address'>
                    Đơn vị in
                  </label>
                  <select
                    className='form-control'
                    value={data?.branch?._id}
                    onChange={updateBranch}
                  >
                    <option value=''>Không có</option>
                    {branchs.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-category'>
                    Thể loại
                  </label>
                  <select
                    className='form-control'
                    value={data?.category}
                    onChange={updateCategory}
                  >
                    <option value=''>Không có</option>
                    {categories.map((cate) => (
                      <option key={cate._id} value={cate._id}>
                        {cate.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='form-group'>
                  <label className='my-2' htmlFor='order-address'>
                    Tải tài liệu
                  </label>
                  <button
                    type='button'
                    onClick={handleDocumentButton}
                    className='form-drive btn btn-outline-primary'
                  >
                    <img
                      alt='icon'
                      src={DriveIcon}
                      width='21px'
                      height='21px'
                    />
                  </button>
                </div>
                <div className='form-group form-instruction'>
                  <label className='my-2'>Hướng dẫn in</label>
                  <textarea
                    className='form-control'
                    rows={3}
                    defaultValue={data?.instruction}
                    name='instruction'
                  />
                </div>

                <div className='form-group'>
                  <label className='my-2'>Thành tiền</label>
                  <div className='d-flex align-items-center'>
                    <CurrencyInput
                      className='form-control'
                      name='cost'
                      value={data?.cost}
                      decimalsLimit={0}
                      readOnly
                    />
                    <button
                      type='button'
                      className='btn btn-primary ms-2'
                      style={{ width: '10rem' }}
                      onClick={() => {
                        if (
                          userRole === 'admin' ||
                          userRole === 'manager' ||
                          userRole === 'cashier'
                        ) {
                          setShowTable(true);
                        } else {
                          ToastWrapper(
                            'Bạn không có quyền thực hiện chức năng này',
                            'error'
                          );
                        }
                      }}
                    >
                      Bảng tính
                    </button>
                  </div>

                  <PriceTable
                    id={data?._id}
                    show={showTable}
                    setShow={setShowTable}
                    data={costData}
                    setData={setCostData}
                    cost={data?.cost}
                    setCost={(value) => setData({ ...data, cost: value })}
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2'>Trả trước</label>
                  <CurrencyInput
                    className='form-control'
                    name='cash'
                    placeholder='Nhập số tiền'
                    defaultValue={cash}
                    decimalsLimit={0}
                    onValueChange={(value) => setCash(value)}
                    readOnly={
                      userRole !== 'admin' &&
                      userRole !== 'manager' &&
                      userRole !== 'cashier'
                    }
                  />
                </div>
                <div>
                  <label className='my-2'>Biên lai</label>
                  {data?.receipt ? (
                    <a
                      className='form-drive btn btn-outline-primary'
                      href={data?.receipt}
                      target='_blank'
                      rel='noopenner noreferrer'
                    >
                      <img
                        alt='icon'
                        src={DriveIcon}
                        width='21px'
                        height='21px'
                      />
                    </a>
                  ) : (
                    <input className='form-control' value='Không có' disabled />
                  )}
                </div>
                <div className='form-group'>
                  <label className='my-2'>Còn nợ</label>
                  <CurrencyInput
                    style={data?.cost - data?.cash > 0 ? { color: 'red' } : {}}
                    className='form-control'
                    name='cash'
                    placeholder='Số tiền còn nợ'
                    value={data?.cost - data?.cash}
                    decimalsLimit={0}
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2'>Tình trạng</label>
                  {data?.isPrinted ? (
                    <input
                      className='form-control fw-bold'
                      style={{ color: '#0d6efd' }}
                      value='Đã in'
                      readOnly
                    />
                  ) : (
                    <input
                      className='form-control fw-bold'
                      style={{ color: 'red' }}
                      value='Chưa in'
                      readOnly
                    />
                  )}
                </div>
                <div className='form-group'>
                  <label className='my-2'>Mã giảm giá</label>
                  <input
                    className='form-control fw-bold'
                    name='coupon'
                    defaultValue={data?.coupon}
                  />
                </div>
                <div className='form-group'>
                  <label className='my-2'>Đặt giờ giao hàng</label>
                  <ReactDatePicker
                    showTimeSelect
                    dateFormat='dd/MM/yyyy HH:mm'
                    timeFormat='HH:mm'
                    selected={deliveryTime}
                    onChange={(date) => setDeliveryTime(date)}
                  />
                </div>

                <div className='form-group form-note'>
                  <label className='my-2'>Ghi chú</label>
                  <textarea
                    className='form-control'
                    rows={2}
                    defaultValue={data?.note}
                    name='note'
                  />
                </div>
                <div className='tag-container'>
                  {tags &&
                    tags.map((tag) => {
                      return (
                        <div key={tag._id} className='tag'>
                          {tag.name}
                          <button
                            type='button'
                            onClick={() => handleRemoveTag(tag)}
                            className='remove-tag-btn'
                          >
                            <AiOutlineMinus />
                          </button>
                        </div>
                      );
                    })}
                  <button
                    type='button'
                    className='add-tag-btn'
                    onClick={() => setTagShow(true)}
                  >
                    <span>+</span>
                  </button>
                </div>
                <div className='d-flex w-100 align-items-center justify-content-between mb-5'>
                  <div className='quotation d-flex flex-row align-items-center'>
                    <div className='quotation-link d-flex'>
                      {quotationToken ? (
                        <div className='d-flex flex-row align-items-center mx-2'>
                          <a
                            href={quotationLink}
                            alt='quotation-link'
                            target='_blank'
                            rel='noopenner noreferrer'
                          >
                            Báo giá
                          </a>
                          <button
                            className='btn btn-primary mx-2'
                            type='button'
                            onClick={copyQuotationLink}
                          >
                            {quotationCopied
                              ? 'Đã chép báo giá'
                              : 'Chép báo giá'}
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={createQuotationLink}
                          type='button'
                          className='btn btn-primary my-3'
                        >
                          Tạo báo giá
                        </button>
                      )}
                      <button
                        onClick={copyMessage}
                        type='button'
                        className='btn btn-primary m-3'
                      >
                        {messageCopied ? 'Đã chép' : 'Chép'} tin nhắn mẫu
                      </button>
                    </div>
                  </div>
                  <button
                    className='update-button btn btn-primary mx-2 text-uppercase'
                    type={'submit'}
                  >
                    Cập nhật thông tin
                  </button>
                </div>
                <Modal show={tagShow} onHide={() => setTagShow(false)}>
                  <Modal.Header closeButton>Chọn thẻ</Modal.Header>
                  <Modal.Body>
                    <div className='tag-container'>
                      {tagList &&
                        tagList.map((tag) => {
                          return (
                            <button
                              key={tag._id}
                              style={{
                                padding: '0.5rem',
                                margin: '1rem 1rem 1rem 0',
                                border: 'none',
                                borderRadius: '10px',
                                backgroundColor: '#198754',
                                color: 'white',
                              }}
                              onClick={() => handleAddTag(tag)}
                            >
                              {tag.name}
                            </button>
                          );
                        })}
                    </div>
                  </Modal.Body>
                </Modal>
              </div>

              {
                <div className='mx-2 d-flex flex-grow-1 flex-wrap flex-column align-items-start justify-content-center'>
                  {userRole === 'admin' ||
                  userRole === 'manager' ||
                  userRole === 'print' ? (
                    <div className='border-bottom'>
                      <ActionButton
                        active={data?.isPrinted}
                        onClick={handlePrinted}
                      >
                        Xác nhận đã in
                      </ActionButton>
                    </div>
                  ) : null}

                  {userRole === 'admin' ||
                  userRole === 'manager' ||
                  userRole === 'mod' ||
                  userRole === 'cashier' ? (
                    <>
                      <ActionButton
                        loading={stateLoading}
                        active={data?.state === 0}
                        onClick={() => updateState(0)}
                      >
                        Vừa tạo
                      </ActionButton>
                      {/* <ActionButton
                        loading={stateLoading}
                        active={data?.state === 1}
                        onClick={() => updateState(1)}
                      >
                        Chờ cập nhật
                      </ActionButton> */}
                      <ActionButton
                        loading={stateLoading}
                        active={data?.state === 2}
                        onClick={() => updateState(2)}
                      >
                        Đã tiếp nhận và báo giá
                      </ActionButton>
                      <ActionButton
                        loading={stateLoading}
                        active={data?.state === 3}
                        onClick={() => updateState(3)}
                      >
                        Chuyển đến
                        <br />
                        {data?.branch?.name}
                      </ActionButton>
                      <ActionButton
                        loading={stateLoading}
                        active={data?.state === 4}
                        onClick={() => updateState(4)}
                      >
                        Đã giao hàng
                      </ActionButton>
                      <ActionButton
                        loading={stateLoading}
                        active={data?.state === 5}
                        onClick={() => updateState(5)}
                      >
                        Đã hoàn tất
                      </ActionButton>
                      <ActionButton
                        loading={stateLoading}
                        active={data?.state === 6}
                        onClick={() => updateState(6)}
                        bgcolor='red'
                      >
                        Đã hủy
                      </ActionButton>
                    </>
                  ) : null}
                </div>
              }
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showDocumentList}
          onHide={() => setShowDocumentList(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Danh sách tài liệu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data?.uploadType === 'design' && (
              <b>Trung tâm tư vấn hỗ trợ thiết kế mẫu</b>
            )}
            {data?.uploadType === 'file'
              ? data?.document?.map((_item, index) => {
                  const fileId = _item?.fileId;
                  return (
                    <>
                      <div key={fileId}>
                        {index + 1}
                        {'. '}
                        <span>{_item?.fileName}</span>
                      </div>
                      <Button
                        onClick={() => getSignedUrl(_item?.fileId)}
                        className='mb-3 mt-1'
                      >
                        Tải file
                      </Button>
                    </>
                  );
                })
              : data?.document?.map((_item, idx) => {
                  return (
                    <>
                      {idx + 1}
                      {'. '}
                      <a
                        href={_item?.fileUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {_item?.fileUrl}
                      </a>
                    </>
                  );
                })}
            <p className='mt-3'>Hướng dẫn in: {data?.instruction}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default styled(OrderItem)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid #ccc;
  margin-top: 3rem;
  position: relative;

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }

  .form-group {
    flex-grow: 1;
    margin: 0 0.5rem;
  }

  .form-group {
  }

  .form-instruction {
    width: 100%;
  }

  .form-note {
    width: 100%;
  }

  .form-drive {
    display: block;
  }

  .item-index {
    position: absolute;
    top: -2rem;
    width: 100%;
    text-align: center;
    color: #a0a0a0;
  }
  .update-button {
    /* display: block; */
  }

  //tag styles
  .tag-container {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tag {
    padding: 0.5rem;
    margin: 1rem 1rem 1rem 0;
    border: none;
    border-radius: 10px;
    background-color: #198754;
    color: white;
  }

  .add-tag-btn {
    font-weight: bold;
    margin-left: 1rem;
    padding: 0.3rem 0.8rem;
    border-radius: 50px;
    border: 2px solid #198754;
    background-color: white;
    color: #198754;
  }

  .remove-tag-btn {
    margin: 0 0.5rem 0 1rem;
    padding: 0;
    border: none;
    color: white;
    background-color: transparent;
    font-weight: bold;
  }
`;
