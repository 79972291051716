import axiosClient from 'api/axiosClient';
import branchApi from 'api/branchApi';
import categoryApi from 'api/categoryApi';
import orderApi from 'api/orderApi';
import tagApi from 'api/tagApi';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { AiOutlineMinus } from 'react-icons/ai';
import { ToastWrapper } from 'utils';
import styles from './creationForm.module.css';

function CreationForm() {
  const [categories, setCategories] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [category, setCategory] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [fileName, setFileName] = useState('');
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const MAX_CATEGORY_PAGE = 0;
    const MAX_CATEGORY_LIMIT = 10;
    categoryApi.getCategories(MAX_CATEGORY_PAGE, MAX_CATEGORY_LIMIT).then((res) => {
      setCategories(res.data.sort((a, b) => a.priority - b.priority));

      if (res.data.length > 0) {
        setCategory(res.data[0]);
      }
    });
  }, []);

  useEffect(() => {
    const MAX_BRANCH_PAGE = 0;
    const MAX_BRANCH_LIMIT = 10;
    branchApi
      .getBranchs(MAX_BRANCH_PAGE, MAX_BRANCH_LIMIT)
      .then((res) => {
        setBranchs(res.data);
      })
      .catch((err) => ToastWrapper(err.response.data.message), 'error');
  }, []);

  useEffect(() => {
    const MAX_TAG_PAGE = 0;
    const MAX_TAG_LIMIT = 100;
    tagApi
      .getTags(MAX_TAG_PAGE, MAX_TAG_LIMIT, { category })
      .then((res) => {
        setTagList(res.data);
      })
      .catch((e) => console.log(e));
  }, [category]);

  const uploadFile = (e) => {
    const file = e.target.files[0];

    if (file.size > 52428800) {
      ToastWrapper('Kích thước tệp không được vượt quá 50MB', 'error');
      document.getElementById('formFile').value = null;
      return;
    }

    setFileName(file.name);
    setUploading(true);
    const formData = new FormData();
    formData.append('document', file);

    axiosClient
      .post('/orders/upload', formData, {
        onUploadProgress: (progressEvent) => {
          const percent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          setUploadPercent(percent / 2);

          if (percent == 100) {
            setTimeout(() => {
              setUploadPercent(75);
            }, 1500);
          }
        },
      })
      .then((res) => {
        setDocumentId(res.data.documentId);
        setUploadPercent(100);
        setUploading(false);
      })
      .catch((err) => {
        setUploading(false);
        ToastWrapper(err.response.data.message, 'error');
      });
  };

  const clearFile = () => {
    document.getElementById('formFile').value = null;
    setDocumentId(null);
    setFileName('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const category = document.getElementById('formCategory').value;
    const name = document.getElementById('formName').value;
    const tel = document.getElementById('formTel').value;
    const zalo = document.getElementById('formZalo').value;
    const address = document.getElementById('formAddress').value;
    const note = document.getElementById('formNote').value;
    const instruction = document.getElementById('formInstruction').value;
    const documentLink = document.getElementById('formDocumentLink')?.value;

    if (!documentId && !documentLink) {
      ToastWrapper('Vui lòng tải lên tệp đính kèm hoặc nhập link tài liệu', 'error');
      return;
    }

    if (!category || !name || !tel || !zalo) {
      ToastWrapper('Vui lòng nhập đầy đủ thông tin', 'error');
      return;
    }

    if (!instruction) {
      ToastWrapper('Vui lòng nhập hướng dẫn in cho nhân viên', 'error');
      return;
    }

    let order = {
      category,
      name,
      tel,
      zalo,
      address,
      note,
      instruction,
      tags: [],
    };

    for (let t of tags) {
      order.tags.push(t._id);
    }

    if (uploading) {
      ToastWrapper('Vui lòng chờ đợi khi đang tải lên tệp đính kèm', 'info');
      return;
    }

    if (documentId) {
      order['document'] = `https://drive.google.com/file/d/${documentId}`;
    } else {
      order['document'] = documentLink;
    }

    orderApi
      .createOrder(order)
      .then((res) => {
        ToastWrapper('Tạo đơn hàng thành công', 'success');
        return;
      })
      .catch((e) => {
        console.log(e);
        ToastWrapper(e.response.data.message, 'error');
      });
  };

  const handleAddTag = (tag) => {
    for (let child of tags) {
      if (child._id === tag._id) {
        return;
      }
    }

    setTags([...tags, tag]);
  };

  const handleRemoveTag = (tag) => {
    const newTags = tags.filter((t) => t._id !== tag._id);
    setTags(newTags);
  };

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>Chọn thẻ</Modal.Header>
        <Modal.Body>
          <div className={styles.tagContainer}>
            {tagList &&
              tagList.map((tag) => {
                return (
                  <button key={tag._id} value={tag} className={styles.tag} onClick={() => handleAddTag(tag)}>
                    {tag.name}
                  </button>
                );
              })}
          </div>
        </Modal.Body>
      </Modal>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 mt-5" controlId="formFile" onChange={uploadFile}>
          <Form.Label className="btn btn-success">Tải tệp lên</Form.Label>
          <p className="my-0">{fileName}</p>
          <Form.Control type="file" style={{ display: 'none' }} />
        </Form.Group>
        <Form.Group>
          {uploading ? <Form.Text>Đang tải tệp lên... {uploadPercent}%</Form.Text> : null}
        </Form.Group>
        <Button variant="danger" className="mt-1 mb-3" onClick={clearFile}>
          Xóa tệp
        </Button>
        {!documentId ? (
          <Form.Group className="mb-3" controlId="formDocumentLink">
            <Form.Label>Hoặc nhập liên kết đến tài liệu</Form.Label>
            <Form.Control type="text" placeholder="Nhập liên kết đến tài liệu" />
          </Form.Group>
        ) : null}
        <Form.Group className="mb-3" controlId="formCategory" onChange={(e) => setCategory(e.target.value)}>
          <Form.Label>Thể loại</Form.Label>
          <Form.Select>
            {categories.map((category) => {
              return (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formCategory">
          <Form.Label>Gắn thẻ</Form.Label>
          <div className={styles.tagContainer}>
            {tags &&
              tags.map((tag) => {
                return (
                  <div key={tag._id} className={styles.tag}>
                    {tag.name}
                    <button
                      type="button"
                      onClick={() => handleRemoveTag(tag)}
                      className={styles.removeTagBtn}
                    >
                      <AiOutlineMinus />
                    </button>
                  </div>
                );
              })}
            <button type="button" className={styles.addTagBtn} onClick={() => setShow(true)}>
              <span>+</span>
            </button>
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formInstruction">
          <Form.Label>Hướng dẫn in</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Nhập hướng dẫn in cho nhân viên" />
        </Form.Group>

        {/* <Form.Text>Thông tin khách hàng</Form.Text> */}
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Tên khách hàng</Form.Label>
          <Form.Control type="text" placeholder="Nhập họ tên đầy đủ của khách hàng" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formTel">
          <Form.Label>Số điện thoại</Form.Label>
          <Form.Control type="text" placeholder="Nhập số điện thoại" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formZalo">
          <Form.Label>Zalo</Form.Label>
          <Form.Control type="text" placeholder="Nhập số điện thoại Zalo" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAddress">
          <Form.Label>Địa chỉ giao hàng</Form.Label>
          <Form.Control type="text" placeholder="Bỏ trống nếu nhận tại cửa hàng" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAddress">
          <Form.Label>Chi nhánh nhận hàng</Form.Label>
          <Form.Select>
            {branchs.map((branch) => {
              return (
                <option key={branch._id} value={branch._id}>
                  {branch.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNote">
          <Form.Label>Ghi chú (nếu có)</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Nhập ghi chú cho đơn hàng" />
        </Form.Group>
        <Button className="mt-1 mb-5" variant="success" type="submit">
          Tạo đơn hàng
        </Button>
      </Form>
    </div>
  );
}

export default CreationForm;
