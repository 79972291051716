import orderApi from 'api/orderApi';
import React from 'react';
import { useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastWrapper } from 'utils';
import Item from '../components/Item';

function StatisticPage() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    orderApi
      .getStatistics(startDate.getTime(), endDate.getTime())
      .then((res) => {
        setData(res.data)
      })
      .catch((e) => ToastWrapper(e?.response?.data?.message, 'error'));
  };

  return (
    <div>
      <Form onSubmit={onSubmit} className="d-flex lign-items-end flex-wrap">
        <Form.Group className="mb-2">
          <Form.Label>Ngày bắt đầu</Form.Label>
          <DatePicker showTimeSelect selected={startDate} onChange={(date) => setStartDate(date)} />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>Ngày kết thúc</Form.Label>
          <DatePicker showTimeSelect selected={endDate} onChange={(date) => setEndDate(date)} />
        </Form.Group>
        <Button type="submit" className="mb-2">
          Thống kê
        </Button>
      </Form>
      <div>
        <Table striped bordered hover className="mt-5">
          <thead>
            <th>Tổng đơn đã in</th>
            <th>Tổng tiền</th>
            <th>Tổng cọc</th>
            <th>Còn thiếu</th>
          </thead>
          <tbody>
            <tr>
              <td>{data?.data?.length || 0}</td>
              <td>{data?.totalCost || 0}</td>
              <td>{data?.totalCash || 0}</td>
              <td>{(data?.totalCost - data?.totalCash) || 0}</td>
            </tr>
          </tbody>
        </Table>
        
      </div>
      <div className='mt-5'>
        <h2 className='text-center'>Đơn hàng</h2>
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Ngày tạo</th>
            <th>Mã đơn hàng</th>
            <th>Khách hàng</th>
            <th>Trạng thái</th>
            <th>Tình trạng in</th>
            <th>Hành động</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((child, idx) => {
            return <Item idx={idx} key={child._id} {...child} />;
          })}
        </tbody>
      </Table>
      </div>
    </div>
  );
}

export default StatisticPage;
